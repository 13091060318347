import { template as template_7f8760aefdb748ddb08fe87c6c2ef276 } from "@ember/template-compiler";
const FKText = template_7f8760aefdb748ddb08fe87c6c2ef276(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
