import { template as template_1fa501c6c6fa4ef2aed5c42f3a4a2525 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_1fa501c6c6fa4ef2aed5c42f3a4a2525(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_1fa501c6c6fa4ef2aed5c42f3a4a2525(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_1fa501c6c6fa4ef2aed5c42f3a4a2525(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
